import { useState } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { unset } from "lodash";
import { Link } from "react-router-dom";

import './codigoAutForm.scss'
import { useTranslation } from 'react-i18next';
import Spinner from '../shared/components/Spinner';

export default function CodigoAutForm({
    presupuesto,
    conCodigo,
    onSubmitCodigoAut
}) {
    const schema = yup.object({
        matricula: yup.string(),
        codigo_autorizacion: conCodigo ? yup.string().nullable().required("El código de autorización es obligatorio") : yup.string().nullable(),
    }).required();
    const { t } = useTranslation()
    const [condiciones, setCondiciones] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { 
        register, 
        handleSubmit,
        getValues,
        formState: { errors },
    } = useForm({
            mode: "onChange",
            resolver: yupResolver(schema),
            defaultValues: {
                presupuesto_id: presupuesto.id,
                codigo_autorizacion: '',
                matricula: '',
            }
    });

    const onSubmit = async (data, e) => {
        e.preventDefault();
        if(isLoading) return
        
        setIsLoading(true)
        data.codigo_autorizacion = data.codigo_autorizacion + ' ' + data.matricula;
        unset(data.matricula);
        await onSubmitCodigoAut(data);
        setIsLoading(false)
    }

    const isDisabled = () => { return !condiciones && getValues('codigo_autorizacion') === ""}
    const handleChangeCondiciones = (e) => { setCondiciones(e.target.checked); }

    return (
        <div className="w-100">
            <form className="form mb-4 flex-grow-1 ms-0 " onSubmit={handleSubmit(onSubmit)}>
                <input 
                    type="hidden" 
                    {...register("presupuesto_id")} 
                    defaultValue={presupuesto.id} />


                <div className="w-100 d-flex flex-column">
                    <div className="w-100"> 
                        { (conCodigo) ?
                            <div className='codigo-aut-form'>
                                <div className="form-group">
                                    <label htmlFor="codigo_autorizacion">{t('carrito.codigo-aut.codigo')}</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        {...register("codigo_autorizacion", { required: true, maxLength: 50 })} 
                                        defaultValue={''} />

                                    <div className="form-invalid">
                                        {(errors.codigo_autorizacion) && errors.codigo_autorizacion?.message}
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="matricula">{t('carrito.codigo-aut.matricula')}</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        {...register("matricula", { maxLength: 50 })} 
                                        defaultValue={''} />
                                </div>
                            </div>
                          : ''
                        }

                        <div className='carrito__pedido--submit'>
                            <div>
                                <input 
                                type="checkbox"
                                name="condiciones"
                                onChange={handleChangeCondiciones}
                                defaultChecked={false} />
                                
                                <p>
                                    {t('carrito.codigo-aut.checkbox.1')} 
                                    <Link to={"/condiciones"} title="Condiciones Generales de Compra" target={"_blank"}>
                                        <span className="link fw-bold ms-1">{t('carrito.codigo-aut.checkbox.2')}</span>
                                    </Link>
                                </p>
                            </div>
                            <button type="submit" disabled={isDisabled()}>{isLoading ? <Spinner className='spinner-pedido-section'/> : t('carrito.codigo-aut.submit')}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}